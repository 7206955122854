<template>
  <div class="chat-container">
    <div class="messages" ref="messagesContainer">
      <div v-for="message in messages" :key="message.id" :class="['message-container', message.type]">
        <img :src="message.type === 'bot' ? 'http://img.suyelu.pro/qingsong/avatars/sys.png' : 'http://img.suyelu.pro/qingsong/avatars/user.png'" class="avatar" />
        <div :class="['message', message.type]">
          <p>{{ message.text }}</p>
        </div>
      </div>
    </div>
    <div v-if="isThankful" class="thankful-message">
      <p>感谢您使用我们的服务！</p>
      <button @click="copyLastMessage">复制最后一条信息</button>
    </div>
    <div class="input-container">
      <input v-model="inputText" @keyup.enter="handleSubmit" :placeholder="inputPlaceholder" :disabled="isInputDisabled" />
      <button @click="handleSubmit" :disabled="isInputDisabled">发送</button>
    </div>

  </div>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';
export default {
  name: 'ChatComponent',
  data() {
    return {
      inputText: '',
      messages: [],
      isThankful: false,
      isInputDisabled: false,
      inputPlaceholder: '输入你的消息...'
    };
  },
  async mounted() {
    await this.checkUserCookie(); // 在组件加载时检查用户的 Cookie
    this.displayInitialMessages();
  },
  methods: {
    checkUserCookie() {
      return new Promise((resolve) => {
        axios.get('/api/checkcookie', { withCredentials: true })
          .then((response) => {
            if (response.data.visited) {
              Swal.fire({
                title: '已访问过，请退出',
                text: '您已经访问过我们的服务，不能重复提交。',
                icon: 'info',
                confirmButtonText: '直接关闭退出',
                confirmButtonColor: "#007bff"
              }).then(() => {
                // 尝试关闭当前页面
                this.isInputDisabled = true;
                this.inputPlaceholder = '输入已禁用';
                this.scrollToBottom();
                window.close();
                setTimeout(() => {
                  // 如果无法关闭，显示提示用户手动关闭
                  if (!window.closed) {
                    Swal.fire({
                      title: '请关闭此页面',
                      text: '由于浏览器限制，请手动关闭该页面。',
                      icon: 'warning',
                      confirmButtonText: '好的',
                      confirmButtonColor: "#007bff"
                    }).then(() => {
                      this.isInputDisabled = true;
                      this.inputPlaceholder = '输入已禁用';
                      this.scrollToBottom();
                    });
                  }
                }, 500);
                resolve(); // 继续处理Promise
              });
            } else {
              resolve(); // 如果没有访问过，立即 resolve
            }
          })
          .catch((error) => {
            console.error('Error checking cookie:', error);
            resolve(); // 如果出现错误，也 resolve，以便继续进行
          });
      });
    },
    displayInitialMessages() {
        const initialMessages = [
          { text: '你好，我是一个AI文本生成器。你可以在下方对话框输入问题或需求！', type: 'bot' },
          { text: '输入内容问题或文本时要尽量清晰明确，以便大语言模型可以更好地理解您的需求。大语言模型生成文本之后，您可复制该文本到问卷界面的输入框中进行修改或调整。', type: 'bot' },
          { text: '友情提示：该链接只能点击进入一次，同时问题的提交只提供一次。请谨慎输入文本哦！', type: 'bot' }
        ];
        initialMessages.forEach((message) => {
          setTimeout(() => {
            this.messages.push({
              id: this.messages.length + 1,
              ...message
            });
            this.scrollToBottom();
          }); // 每条消息间隔0.8秒
        });
        this.scrollToBottom();
    },
    async handleSubmit() {
      if (this.inputText.trim() === '' || this.isInputDisabled) {
        return;
      }

      // 动态构建URL，将当前路径前面加上 /api/
      const currentPath = window.location.pathname;  // 获取当前路径，例如 "/sa"
      const apiUrl = `/api${currentPath}`;  // 将路径前面加上 "/api"，例如 "/api/sa"

      // 添加用户消息
      const userMessage = { id: this.messages.length + 1, text: this.inputText, type: 'user' };
      this.messages.push(userMessage);
      this.scrollToBottom();

      try {
        const response = await axios.post(apiUrl,
          new URLSearchParams({
            prompt: this.inputText
          }),
          { 
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            withCredentials: true // 启用凭证（即Cookie） 
          }
        );

        // 添加机器人回复消息
        setTimeout(() => {
          const botMessage = { id: this.messages.length + 1, text: response.data.message, type: 'bot' };
          this.messages.push(botMessage);
          this.scrollToBottom();

          // 设置感谢消息
          if (!this.isThankful) {
            this.isThankful = true;
            this.isInputDisabled = true;
            this.inputPlaceholder = '输入已禁用';
          }
        }, 1000);
      } catch (error) {
        console.error('Error sending message:', error);
        Swal.fire(`请求失败: ${error.message}`);
      }

      this.inputText = '';
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const container = this.$refs.messagesContainer;
        container.scrollTop = container.scrollHeight;
      });
    },
    copyLastMessage() {
      console.log('copyLastMessage triggered'); // 检查事件是否触发

      const lastMessage = this.messages[this.messages.length - 1].text;
      if(!lastMessage) {
        Swal.fire('没有可复制的消息！');
        return;
      }
      if(navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard.writeText(lastMessage)
        .then(() => {
          // 使用 SweetAlert2 来展示自定义通知
          Swal.fire({
            title: '复制成功',
            text: '最后一条信息已复制到剪贴板！',
            icon: 'success',
            confirmButtonText: '好的',
            confirmButtonColor: "#007bff"
          });
        })
        .catch(err => {
          console.error('Failed to copy text: ', err);
          Swal.fire({
            title: '复制失败',
            text: '请检查浏览器设置或权限！',
            icon: 'error',
            confirmButtonText: '好的',
            confirmButtonColor: "#007bff"
          }).then(() => {
                // 尝试关闭当前页面
                window.close();
            });
        });
      } else {
        // 使用 document.execCommand 作为回退方案
        const textarea = document.createElement('textarea');
        textarea.value = lastMessage;
        textarea.style.position = 'fixed';  // 防止闪烁
        document.body.appendChild(textarea);
        textarea.focus();
        textarea.select();

        try {
          const successful = document.execCommand('copy');
          if (successful) {
            Swal.fire({
              title: '复制成功',
              text: '最后一条信息已复制到剪贴板！',
              icon: 'success',
              confirmButtonText: '好的',
              confirmButtonColor: "#007bff"
            }).then(() => {
                // 尝试关闭当前页面
                window.close();
              });
          } else {
            Swal.fire({
              title: '复制失败',
              text: '请手动复制！',
              icon: 'error',
              confirmButtonText: '好的',
              confirmButtonColor: "#007bff"
            });
          }
        } catch (err) {
          console.error('Fallback: Oops, unable to copy', err);
          Swal.fire({
            title: '复制失败',
            text: '请手动复制！',
            icon: 'error',
            confirmButtonText: '好的',
            confirmButtonColor: "#007bff"
          });
        }
        document.body.removeChild(textarea);
      }
    }
  }
};
</script>

<style scoped>
/* 针对手机端的样式 */
@media only screen and (max-width: 600px) {
  .chat-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    max-width: 100vw;
    background-color: #f5f5f5;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }

  .messages {
    flex: 1;
    padding: 20px;
    overflow-y: auto;
  }

  .message-container {
    display: flex;
    align-items: flex-start; /* 确保头像和文本垂直居中对齐 */
    margin:15px 0;
  }

  .avatar {
    width: 30px; /* 头像的宽度 */
    height: 30px; /* 头像的高度 */
    border-radius: 50%; /* 使头像成为圆形 */
    margin-right: 10px; /* 头像和文本之间的间距 */
  }

  .message {
    padding: 10px 20px;
    border-radius: 10px;
    max-width: 80%;
    word-wrap: break-word;
  }

  /* AI 回复信息：文本左对齐 */
  .message.bot {
    background-color: #e2e3e5;
    color: #495057;
    text-align: left;
    font-size: 16px;
    width: 300px;
    max-width: 300px;
    padding: 0px 10px;
  }

  /* 用户输入：文本右对齐，box 右对齐 */
  .message.user {
    background-color: #d1e7dd;
    color: #0f5132;
    text-align: left;
    font-size: 16px;
    margin-left: auto;
    width: auto;
    max-width: 300px;
    padding: 0px 10px;
  }
  p{
    margin-top: 4px;
    margin-bottom: 4px;
  }
  .message-container.user {
    flex-direction: row-reverse; /* 将头像放在右侧 */
  }

  .message-container.user .avatar {
    margin-left: 10px; /* 调整用户头像和文本的间距 */
    margin-right: 0; /* 去掉右侧间距 */
  }

  .input-container {
    display: flex;
    border: 1px solid #ccc;
    padding: 10px;
    background-color: #fff;
    margin-bottom: 10px;
  }

  input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 20px;
    margin-right: 10px;
    outline: none;
    font-size: 16px;
  }

  button {
    padding: 10px 20px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 16px;
  }

  button:hover {
    background-color: #0056b3;
  }

  button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  .thankful-message {
    text-align: center;
    padding: 20px;
    background-color: #e0ffe0;
    border-radius: 10px;
  }
}
@media only screen and (min-width: 601px) {
  .chat-container {
    display: flex;
    flex-direction: column;
    height: calc(80vh - 60px);
    width: 80vw;
    margin: 30px 30px;
    background-color: #f5f5f5;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }

  .messages {
    flex: 1;
    padding: 10px;
    overflow-y: auto;
    max-width: 100%;
  }

  .message-container {
    display: flex;
    align-items: flex-start; /* 确保头像和文本垂直居中对齐 */
    margin: 10px 0;
  }

  .avatar {
    width: 40px; /* 头像的宽度 */
    height: 40px; /* 头像的高度 */
    border-radius: 50%; /* 使头像成为圆形 */
    margin-right: 10px; /* 头像和文本之间的间距 */
  }

  .message {
    padding: 10px 20px;
    border-radius: 20px;
    max-width: 80%;
    word-wrap: break-word;
  }

  /* AI 回复信息：文本左对齐 */
  .message.bot {
    background-color: #e2e3e5;
    color: #495057;
    text-align: left;
  }

  /* 用户输入：文本右对齐，box 右对齐 */
  .message.user {
    background-color: #d1e7dd;
    color: #0f5132;
    text-align: left;
    margin-left: auto;
    width: auto;
    max-width: 300px;
  }

  .message-container.user {
    flex-direction: row-reverse; /* 将头像放在右侧 */
  }

  .message-container.user .avatar {
    margin-left: 10px; /* 调整用户头像和文本的间距 */
    margin-right: 0; /* 去掉右侧间距 */
  }

  .input-container {
    display: flex;
    border-top: 1px solid #ccc;
    padding: 10px;
    background-color: #fff;
  }

  input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 20px;
    margin-right: 10px;
    outline: none;
    font-size: 16px;
  }

  button {
    padding: 10px 20px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  button:hover {
    background-color: #0056b3;
  }

  button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  .thankful-message {
    text-align: center;
    padding: 20px;
    background-color: #e0ffe0;
    border-radius: 10px;
  }
}

</style>
