<template>
  <div id="app">
      <ChatComponent/>
  </div>
</template>

<script>
import ChatComponent from './components/ChatComponent.vue';

export default {
  name: 'App',

  components: {
    ChatComponent,
  }
};
</script>

<style>
@media only screen and (max-width: 600px) {
  #app {   
    font-family: Avenir, Helvetica, Arial, sans-serif;   
    -webkit-font-smoothing: antialiased;   
    -moz-osx-font-smoothing: grayscale;   
    text-align: center;   color: #2c3e50;   
    justify-content: center;   
    align-items: center;   
    max-width: 2000px;   
    height: 100vh;   
    background-color: #f0f2f5; }
}
@media only screen and (min-width: 601px) {
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f2f5;
}
}
</style>

